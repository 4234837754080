<template>
  <div>
    <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
      <ToolbarList
        :title="$t('labels.mepa_codes')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withSearch="true"
        :withAdd="true"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        @onSearch="searchFunc"
      ></ToolbarList>
      <BaseGrid
        tableName="mepa_codes"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :withEdit=true
        @onEdit="handleEdit"
      ></BaseGrid>
    </v-card>
    
    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <MepaCodeForm
        v-if="formDialog"
        :mode="formMode"
        :selectedItem="formItem"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></MepaCodeForm>
    </v-dialog>
     
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ToolbarList from "@/components/Shared/ToolbarList";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";
import MepaCodeForm from "@/components/MepaCode/MepaCodeForm";

const computed = {
  ...mapGetters("mepaCode", ["mepaCodes", "total", "onLoading"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  mepaCodesList() {
    return this.mepaCodes;
  },
};

export default {
  name: "mepaCodesList",
  components: {
    ToolbarList,
    BaseGrid,
    MepaCodeForm,
  },
  props: {
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String,
    },
  },
  data() {
    return {
      resourceType: this.$t("resource_types.mepa_codes"),
      search: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["code"],
        sortDesc: [true]
      },
    };
  },
  mixins: [FormsHelper, Enums],

  methods: {
    ...mapActions("mepaCode", ["ajaxMepaCodeFetchAll"]),

    async fetchData(needle = null) {
      const _search = needle ? needle : this.search || "";
      /*const _filter = this.$props.withModelId
        ? { tenant_id: this.$props.withModelId }
        : null;
        */

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        //filter: _filter,
        pagination: this.tableOptions,
      });

      await this.ajaxMepaCodeFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },

    mapHeaders() {
      const tableHeaders = [];
      if (this.listMode === Enums.LIST_MODE.FULL) {
        tableHeaders.push({
          text: "Code",
          value: "code",
          align: "start",
          sortable: true,
        });
        tableHeaders.push({
          text: "Description",
          value: "description",
          sortable: true,
          align: "start",
        });
        tableHeaders.push({
          text: "Enabled",
          value: "enabled",
          align: "start",
          sortable: true,
        });
        /*tableHeaders.push({
          text: "Disabled_at",
          value: "disabled_at",
          align: "start",
          sortable: true,
        });*/
        tableHeaders.push({
          text: "Actions",
          value: "actions",
          align: "center"
        });
      }
      return tableHeaders;
    },
    mapItems() {
      let map = {};
      if (this.listMode === Enums.LIST_MODE.FULL) {
        map = _.map(this.mepaCodesList, (item) => {
          item.fields = {
            code: { data: item.code, dataType: "text", css: "text-xs-left" },
            description: {
              data: item.description,
              dataType: "text",
              css: "text-xs-left",
            },
            enabled: {
              data: item.enabled ? this.$t("labels.yes") : this.$t("labels.no"),
              dataType: "text",
              css: "text-xs-left",
            },
            /*disabled_at: {
              data: item.disabled_at,
              dataType: "date",
              css: "text-xs-left",
            }*/
          };
          /*
          item.click = {
            actionType: "router-link",
            namedRoot: "serviceDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            show: true,
          };
          */
          item.actions = {};
          return item;
        });
      }
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    searchFunc: async function(searchQuery) {
      this.search = searchQuery;
      await this.fetchData();
    },
    async handleEdit(item) {
      this.openFormDialog(Enums.FORM_MODE.UPDATE, item);
    }
  },

  async created() {
    this.formDialog = false;
    await this.fetchData();
  },
  computed,
};
</script>
