import Vue from "vue";
import Router from "vue-router";
import NavigationDuplicated from "vue-router";
import store from "./store";

import Home from "./views/Home.vue";
// import E404 from "@/components/404";
import Login from "@/views/Login";
//import AuthRegistroAccessi from "@/views/AuthRegistroAccessi";
import CustomerLogin from "@/views/Customer/Login";
import ResetPassword from "@/views/ResetPassword";
import LoadConfig from "@/components/LoadConfig";
import ApplicationSettings from "@/components/Settings/ApplicationSettings";
import DocumentsList from "@/views/DocumentsList";
import TenantsList from "@/views/TenantsList.vue";
import TenantDetails from "@/views/TenantDetails";
import UsersList from "@/views/UsersList.vue";
import UserDetails from "@/views/UserDetails";
import ServicesList from "@/views/ServicesList";
import ServiceDetails from "@/views/ServiceDetails";
import CustomersList from "@/views/CustomersList";
import CustomerDetails from "@/views/CustomerDetails";
import OrdersList from "@/views/OrdersList";
import OrderDetails from "@/views/OrderDetails";
import TicketsList from "@/views/TicketsList";
import TicketDetails from "@/views/TicketDetails";
import AgreementsList from "@/views/AgreementsList";
import AgreementsInvoicing from "@/views/AgreementsInvoicing";
import AgreementDetails from "@/views/AgreementDetails";
import AgreementInvoicing from "@/views/AgreementInvoicing";
import GenerationSessionsList from "@/views/GenerationSessionsList";
import GenerationSessionItemsList from "@/views/GenerationSessionItemsList";
import EmailMessageList from "@/views/EmailMessageList";
import ApplicationLogList from "@/views/ApplicationLogList";
import InvoiceList from "@/views/InvoiceList";
import ReportsList from "@/views/ReportsList";
import MepaCodeList from "@/views/MepaCodeList";
import DashBoard from "@/views/DashBoard";
import AmministrazioneTrasparente from "@/views/Customer/AmministrazioneTrasparente";
import FileUploader from "@/views/Customer/FileUploader";
import FolderDetails from "@/views/FolderDetails";
import defineRulesFor from "@/abilities";
import i18n from "./i18n";

Vue.use(Router);

const router = new Router({
  routes: [
    { path: "/", component: Home, name: "home" },
    { path: "/default", component: Home, name: "default" },
    { path: "/login", component: Login, name: "login" },
    //{ path: "/auth_registro_accessi", component: AuthRegistroAccessi, name: "auth_registro_accessi" },
    { path: "/customer_login", component: CustomerLogin, name: "customer_login" },
    { path: "/reset_password/:password_reset_token", component: ResetPassword, name: "reset_password" },
    { path: "/tenants", component: TenantsList, name: "tenants" },
    { path: "/users", component: UsersList, name: "users" },
    { path: "/loading", component: LoadConfig, name: "loading" },
    { path: "/agreements", component: AgreementsList, name: "agreements" },
    {
      path: "/agreements/invoicing",
      component: AgreementsInvoicing,
      name: "invoicing"
    },
    {
      path: "/application_logs",
      component: ApplicationLogList,
      name: "application_log"
    },
    {
      path: "/tenants/:id",
      name: "tenantDetails",
      component: TenantDetails,
      props: true
    },
    {
      path: "/users/:id",
      name: "userDetails",
      component: UserDetails,
      props: true
    },
    {
      path: "/agreements/:id",
      name: "agreementDetails",
      component: AgreementDetails,
      props: true
    },
    {
      path: "/agreements/invoicing/:id",
      name: "AgreementDetailsInv",
      component: AgreementDetails,
      props: true
    },
    {
      path: "/agreements/:id/invoicing",
      name: "agreementInvoicing",
      component: AgreementInvoicing,
      props: true
    },
    {
      path: "/services",
      name: "services",
      component: ServicesList,
      props: true
    },
    {
      path: "/services/:id",
      name: "serviceDetails",
      component: ServiceDetails,
      props: true
    },
    {
      path: "/customers",
      name: "customers",
      component: CustomersList,
      props: true
    },
    {
      path: "/customers/:id",
      name: "customerDetails",
      component: CustomerDetails,
      props: true
    },
    {
      path: "/orders",
      name: "orders",
      component: OrdersList,
      props: true
    },
    {
      path: "/orders/:id",
      name: "orderDetails",
      component: OrderDetails,
      props: true
    },
    { path: "/tickets", component: TicketsList, name: "tickets" },
    {
      path: "/tickets/:id",
      name: "ticketDetails",
      component: TicketDetails,
      props: true
    },
    {
      path: "/generation_sessions",
      name: "generation_sessions",
      component: GenerationSessionsList,
      props: true
    },
    {
      path: "/generation_sessions/:id",
      name: "generationSessionItems",
      component: GenerationSessionItemsList,
      props: true
    },
    {
      path: "/application_settings",
      name: "application_settings",
      component: ApplicationSettings,
      props: true
    },
    {
      path: "/email_messages",
      name: "email_messages",
      component: EmailMessageList,
      props: true
    },
    {
      path: "/invoices",
      name: "invoices",
      component: InvoiceList,
      props: true
    },
    {
      path: "/attachments",
      name: "attachments",
      component: DocumentsList,
      props: true
    },
    {
      path: "/reports/:report_type",
      name: "reports",
      component: ReportsList,
      path: "/dashboard",
      name: "dashboard",
      component: DashBoard
    },
    {
      path: "/amministrazione_trasparente/:withModelId/sections",
      name: "amministrazione_trasparente",
      component: AmministrazioneTrasparente,
      props: true
    },
    {
      path: "/amministrazione_trasparente/:withModelId/sections/:id",
      name: "folderDetails",
      component: FolderDetails,
      props: true
    },
    {
      path: "/mepa_codes",
      name: "mepa_codes",
      component: MepaCodeList
    },
    /*{
      path: "/amministrazione_trasparente/:withModelId/:publicPage",
      name: "fileUploader",
      component: FileUploader,
      props: true
    },*/
    {
      path: "*",
      redirect: "/"
    }
  ]
});

router.pushCatch = function (route) {
  router.push(route).catch(() => { });
};

router.beforeEach(async (to, from, next) => {
  if (!localStorage.locale) localStorage.locale = "it";
  i18n.locale = localStorage.locale;

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["login", "reset_password", "register", "customer_login"];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("jwt");
  const loginPath = window.location.pathname;
 
  if (!authRequired) {
    if ((from.name == "dashboard" || from.name == "amministrazione_trasparente" || from.name == "fileUploader") && to.name == "login") {
      router.pushCatch({ name: "customer_login" }); 
    } else {
      next();
    }
  } else if (!loggedIn) {
    //console.log("applicationShutdown");
    await store.dispatch("login/sessionClose");
    await store.dispatch("common/completeConfiguration");
    return next({ name: "login", query: { from: loginPath } });
  } else if (to.name == "loading") next();
  else if (
    store.getters["login/isLogged"] &&
    store.getters["login/loggedUser"]
  ) {
    //console.log("applicationReconfig");
    await store.dispatch("common/startConfiguration");
    await store.dispatch("login/ajaxSetProfile");
    router.app.$ability.update(
      defineRulesFor(store.getters["login/loggedUser"])
    );
    await store.dispatch("common/completeConfiguration");

    var to_check = {
      agreements: "agreements",
      tickets: "tickets",
      orders: "orders",
      tenants: "tenants",
      services: "services",
      customers: "customers",
      generation_sessions: "generation_sessions",
      application_logs: "application_logs",
      users: "users",
      tenantDetails: "tenants",
      agreementDetails: "agreements",
      agreementInvoicing: "agreements",
      serviceDetails: "services",
      customerDetails: "customers",
      orderDetails: "orders",
      ticketDetails: "tickets",
      application_settings: "application_settings",
      attachments: "attachments",
      //dashboard: "dashboard"
    };
    
    if (to.name in to_check && !router.app.$can("route", to_check[to.name])) {
      next("/");
    } else {
      next();
    }

    store.dispatch("login/startTokenWatchdog");
  } else {
    //console.log("applicationShutdown");
    await store.dispatch("login/sessionClose");
    await store.dispatch("common/completeConfiguration");
    next("/");
  }
});

export default router;
