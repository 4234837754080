<template>
  <div>
    <v-card v-if="this.listMode === Enums.LIST_MODE.FULL">
      <ToolbarList
        :title="$t('labels.services')"
        :subtitle="$tc('labels.found_elements', this.total)"
        :withSearch="true"
        :withAdd="true"
        @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        @onSearch="searchFunc"
      ></ToolbarList>
      <BaseGrid
        tableName="services"
        :headers="this.tableData.headers"
        :items="this.tableData.items"
        :totalLength="this.total"
        :pagination="this.tableOptions"
        :loading="this.onLoading"
        @onPaginationChanged="handlePaginationChanged"
        :hideActions="true"
      ></BaseGrid>
    </v-card>
    <v-container v-if="this.listMode === Enums.LIST_MODE.TABBED">
      <v-card>
        <ToolbarTab
          :subtitle="$tc('labels.found_elements', this.total)"
          :withAdd="true"
          @onAdd="openFormDialog(Enums.FORM_MODE.CREATE)"
        ></ToolbarTab>
        <BaseGrid
          tableName="services"
          :headers="this.tableData.headers"
          :items="this.tableData.items"
          :totalLength="this.total"
          :pagination="this.tableOptions"
          :loading="this.onLoading"
          @onPaginationChanged="handlePaginationChanged"
          :hideActions="true"
        ></BaseGrid>
      </v-card>
    </v-container>

    <v-dialog v-model="formDialog" persistent content-class="edit-form-dialog">
      <ServiceForm
        v-if="formDialog"
        :mode="formMode"
        :withModelId="this.withModelId"
        @formSucceed="handleFormSucceed"
        @formCancel="handleFormCancel"
      ></ServiceForm>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import ServiceForm from "@/components/Service/ServiceForm";
import ToolbarList from "@/components/Shared/ToolbarList";
import ToolbarTab from "@/components/Shared/ToolbarTab";
import { HelperService } from "@/services/helper.service";
import BaseGrid from "@/components/Shared/BaseGrid";
import FormsHelper from "@/mixins/formsHelper";
import Enums from "@/mixins/enums";

const computed = {
  ...mapGetters("service", ["services", "total", "onLoading"]),
  _() {
    return _;
  },
  Enums() {
    return Enums;
  },
  servicesList() {
    return this.services;
  }
};

export default {
  name: "servicesList",
  components: {
    ServiceForm,
    ToolbarList,
    ToolbarTab,
    BaseGrid
  },
  props: {
    withModelId: {
      default: null,
      type: String
    },
    withModelType: {
      default: null,
      type: String
    },
    listMode: {
      default: Enums.LIST_MODE.FULL,
      type: String
    }
  },
  data() {
    return {
      resourceType: this.$t("resource_types.service"),
      search: "",
      tableData: { headers: [], items: [] },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["name"]
      }
    };
  },
  mixins: [FormsHelper, Enums],
  /*watch: {
    async withModelId() {
      this.fetchData();
    }
  },*/
  methods: {
    ...mapActions("service", ["ajaxServiceFetchAll"]),
    async fetchData(needle = null) {
      const _search = needle ? needle : this.search || "";
      const _filter = this.$props.withModelId
        ? { tenant_id: this.$props.withModelId }
        : null;

      let payloadFAP = HelperService.buildFilterAndPagination({
        search: _search,
        filter: _filter,
        pagination: this.tableOptions
      });

      await this.ajaxServiceFetchAll(payloadFAP);
      this.initTable();
    },
    initTable() {
      this.tableData.headers = this.mapHeaders();
      this.tableData.items = this.mapItems();
    },
    getPublicPages(item) {
      let public_pages = item.public_page ? JSON.parse(item.public_page) : [];
      if (public_pages && public_pages.length > 0) {
        const publicPageArray = _.map(public_pages, 'page_name');
        public_pages = _.join(publicPageArray, ' - ');
      } else {
        public_pages = "-"
      }
      return public_pages;
    },
    mapHeaders() {
      const tableHeaders = [];
      if (this.listMode === Enums.LIST_MODE.FULL) {
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Note",
          value: "note",
          sortable: true,
          align: "start"
        });
        tableHeaders.push({
          text: "Description",
          value: "description",
          sortable: true,
          align: "start"
        });
        tableHeaders.push({
          text: "Hours",
          value: "hours",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Self autogenerate file",
          value: "self_generated_file",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Public pages",
          value: "public_pages",
          align: "start",
          sortable: false
        });
        tableHeaders.push({
          text: "Tenant",
          value: "tenant_id",
          align: "start",
          sortable: true
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        tableHeaders.push({
          text: "Name",
          value: "name",
          align: "start",
          sortable: true
        });
        tableHeaders.push({
          text: "Note",
          value: "note",
          sortable: true,
          align: "start"
        });
      }
      return tableHeaders;
    },
    mapItems() {
      let map = {};
      if (this.listMode === Enums.LIST_MODE.FULL) {
        map = _.map(this.servicesList, item => {
          item.fields = {
            name: { data: item.name, dataType: "text", css: "text-xs-left" },
            description: { data: item.description, dataType: "text", css: "text-xs-left" },
            note: { data: item.note, dataType: "text", css: "text-xs-left" },
            hours: { data: item.hours, dataType: "text", css: "text-xs-left" },
            self_generated_file: {
              data: item.self_generated_file
                ? this.$t("labels.yes")
                : this.$t("labels.no"),
              dataType: "text",
              css: "text-xs-left"
            },
            public_page: {
              data: this.getPublicPages(item),
              dataType: "text",
              css: "text-xs-left"
            },
            tenantName: {
              data: item.tenant.name,
              dataType: "text",
              css: "text-xs-left"
            }
          };
          item.click = {
            actionType: "router-link",
            namedRoot: "serviceDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            show: true
          };
          item.actions = {};
          return item;
        });
      }
      if (this.listMode === Enums.LIST_MODE.TABBED) {
        map = _.map(this.servicesList, item => {
          item.fields = {
            name: { data: item.name, dataType: "text", css: "text-xs-left" },
            note: { data: item.note, dataType: "text", css: "text-xs-left" }
          };
          item.click = {
            actionType: "router-link",
            namedRoot: "serviceDetails",
            namedRootId: item.id,
            icon: this.$t("icons.detail"),
            css: "text-xs-left",
            show: true
          };
          item.actions = {};
          return item;
        });
      }
      return map;
    },
    handlePaginationChanged(paginationOpts) {
      this.tableOptions = paginationOpts;
      this.fetchData();
    },
    handleFormSucceed() {
      this.closeFormDialog();
      this.fetchData();
    },
    handleFormCancel() {
      this.closeFormDialog();
    },
    searchFunc: async function(searchQuery) {
      this.search = searchQuery
      await this.fetchData();
    },
  },

  async created() {
    this.formDialog = false;
    await this.fetchData();
  },
  computed
};
</script>
