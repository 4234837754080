<template>
  <v-container fluid class="grey lighten-4 title">
    <div
      v-if="mode == Enums.FORM_MODE.CREATE || mode == Enums.FORM_MODE.UPDATE"
    >
      <v-card-title class="form-title title">
        {{ formTitle | capitalize }}
      </v-card-title>
      <v-form class="form-body" @submit.prevent="onSubmit">
        <div class="form-group required">
          <v-text-field
            :label="$t('forms.mepa_codes.code')"
            v-model="form.code"
            name="code"
            v-validate.immediate="'required'"
          ></v-text-field>
          <span
            :class="Settings.style.class.formFieldRequiredCaption"
            v-if="veeErrors.has('code')"
            >{{ veeErrors.first("code") }}</span
          >
        </div>
        <div class="form-group">
          <v-text-field
            :label="$t('forms.mepa_codes.description')"
            v-model="form.description"
            name="description"
          ></v-text-field>
        </div>
        <div class="form-group">
          <v-checkbox
            v-model="form.enabled"
            :label="$t('forms.mepa_codes.enabled')"
            name="enabled"
          >
          </v-checkbox>
        </div>

        <!-- BUTTONS -->
        <FormButtons
          @onSave="onSubmit"
          @onCancel="onCancel"
          :disabled="veeErrors.any()"
        />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import _ from "lodash";
import Enums from "@/mixins/enums";
import SharedMixin from "@/mixins/shared";
import { mapActions, mapGetters } from "vuex";
import FormButtons from "@/components/Shared/FormButtons";

export default {
  props: {
    mode: {
      default: Enums.FORM_MODE.CREATE,
      type: String,
    },
    selectedItem: Object,
  },
  data() {
    return {
      progress: true,
      resourceType: this.$t("resource_types.mepa_codes"),
      form: {
        id: "",
        code: "",
        description: "",
        enabled: true,
      },
    };
  },
  components: { FormButtons },
  mixins: [SharedMixin, Enums],

  methods: {
    ...mapActions("mepaCode", ["ajaxMepaCodeCreateOrUpdate"]),

    async onSubmit() {
      const validate = await this.$validator.validateAll();
      if (validate) {
        await this.submitToStore();
      } else {
        alert(this.$t("forms.common.errorValidateFields"));
      }
    },
    async onCancel() {
      this.$emit("formCancel");
    },
    async submitToStore() {
      const payload = _.clone(this.form);
      await this.createOrUpdateHelper(
        this.mode,
        this.resourceType,
        this.ajaxMepaCodeCreateOrUpdate,
        payload,
        (payload) => payload.p.code,
        (payload) => payload.p.code
      );
      this.$emit("formSucceed");
    },
    setForm(row = null) {
      if (row && !_.isEmpty(row)) {
        this.form.id = row.id;
        this.form.code = row.code;
        this.form.description = row.description;
        this.form.enabled = row.enabled;
      } else if (row == null) {
        this.form.id = "";
        this.form.code = "";
        this.form.description = "";
        this.form.enabled = true;
      }
    },
    initForm() {
      if (this.mode == Enums.FORM_MODE.CREATE) {
        this.setForm();
      } else {
        this.setForm(this.$props.selectedItem);
      }
    },
  },
  computed: {
    Enums() {
      return Enums;
    },
    formTitle() {
      var title = "";
      switch (this.mode) {
        case Enums.FORM_MODE.CREATE:
          title = this.$t("labels.createResourceType", {
            resourceType: this.resourceType || "-",
          });
          break;
        case Enums.FORM_MODE.UPDATE:
          title = this.$t("labels.updateResourceTypeName", {
            resourceType: this.resourceType || "-",
            resourceName: this.form.code || "-",
          });
          break;

        default:
          break;
      }
      return title;
    },
  },
  created() {
    this.initForm();
  },
};
</script>

<style scoped>
.has-error {
  color: red;
}
.required label:before {
  color: #e32;
  content: "* ";
  display: inline;
}
.summary-font {
  font-size: small;
  line-height: 24px;
}
.summary-font-bold {
  font-size: small;
  font-weight: bold;
  line-height: 24px;
}
</style>
