var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        {
          staticClass: "body-1 font-weight-light nowrap",
          attrs: { cols: "4" }
        },
        [_vm._v(_vm._s(_vm._f("upperCase")(this.label)))]
      ),
      this.as === _vm.Enums.DISPLAY_AS.TEXT
        ? _c("v-col", { staticClass: "body-1", attrs: { cols: "5" } }, [
            _vm._v(_vm._s(this.value || "-"))
          ])
        : _vm._e(),
      this.as === _vm.Enums.DISPLAY_AS.LINK && !_vm.custom
        ? _c("v-col", { staticClass: "body-1", attrs: { cols: "5" } }, [
            _c("a", { attrs: { href: this.value, target: "_blank" } }, [
              _vm._v(_vm._s(this.value))
            ])
          ])
        : _vm._e(),
      this.as === _vm.Enums.DISPLAY_AS.LINK && _vm.custom
        ? _c("v-col", { staticClass: "body-1", attrs: { cols: "5" } }, [
            _c("a", { attrs: { href: _vm.resolve() } }, [
              _vm._v(_vm._s(this.value))
            ])
          ])
        : _vm._e(),
      this.as === _vm.Enums.DISPLAY_AS.BOOLEAN
        ? _c("v-col", { staticClass: "body-1", attrs: { cols: "5" } }, [
            _vm._v(
              _vm._s(
                this.value === true || this.value === 1
                  ? _vm.$t("labels.yes")
                  : _vm.$t("labels.no")
              )
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }