import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import { MepaCodeService } from "@/services/mepaCode.service";
import { HelperService } from "@/services/helper.service";

Vue.use(Vuex);

const state = {
  mepaCodes: [],
  selected: {},
  loading: false,
  totalCount: 0
};

const getters = {
  mepaCodes: state => state.mepaCodes,
  selectedMepaCode: state => state.selected,
  onLoading: state => state.loading,
  total: state => state.totalCount
};

const mutations = {
  setList(state, sel) {
    state.mepaCodes = sel;
  },
  setSelected(state, sel) {
    state.selected = sel;
  },
  setTotal(state, sel) {
    state.totalCount = Number(sel);
  },
  setLoading(state, sel) {
    state.loading = sel;
  }
};

const actions = {
  async ajaxMepaCodeSetSelected({ commit }, payload) {
    await HelperService.genericSetSelected(
      commit,
      MepaCodeService.fetchOne,
      payload.id
    );
  },
  
  async ajaxMepaCodeFetchAll({ commit }, payloadFAP) {
    let searchFilterPagination = HelperService.localToJSONAPIPagination(
      payloadFAP
    );
    await HelperService.genericFetchAll(
      commit,
      MepaCodeService.fetchAll,
      searchFilterPagination,
      null
    );
  },

    async ajaxMepaCodeCreateOrUpdate({ commit }, payload) {
        return HelperService.genericCreateOrUpdate(
        commit,
        MepaCodeService.createOrUpdate,
        payload
        );
    },


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
